var arr = [
  {
    title: 'الرئيسية',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'المستخدمين',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'طلبات الدفع',
    route: 'orders',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'الأجهزة / الأرقام',
    route: 'devices',
    icon: 'SmartphoneIcon',
  },
  {
    title: 'أرشيف الإرسال',
    route: 'archive',
    icon: 'ArchiveIcon',
  },
  {
    title: 'المسوقين',
    route: 'resellers/list',
    icon: 'LinkIcon',
  },
  {
    title: 'طلبات سحب المسوقين',
    route: 'resellers/orders',
    icon: 'DollarSignIcon',
  },
  {
    title: 'الاجهزة المسموحة بدون تأكيد',
    route: 'allowed',
    icon: 'PhoneIcon',
  },
  {
    title: 'تسويق الSMS',
    icon: 'SendIcon',
    children: [
      {
        title: 'احصائيات',
        route: 'marketing-stats',
      },
      {
        title: 'عملية جديدة',
        route: 'marketing-new',
      },
      {
        title: 'استعراض العمليات',
        route: 'marketing-list',
      },
      {
        title: 'المسوقين الرايقين',
        route: 'marketing-resellers',
      }
    ],
  },
  {
    title: 'API',
    href: 'https://documenter.getpostman.com/view/14352330/2s8YzXuKNB',
    icon: 'CodeIcon',
  },
], arr2 = [];

arr.forEach(function (e) {

  arr2.push(e)

})


export default arr2
